<template>
  <BaseList
    :list-query="list"
    route="herDenmarkDetail"
    locale-section="pages.herDenmark"
  />
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "HerDenmarkList",
  components: {
    BaseList: () => import("@/components/BaseList")
  },
  data() {
    return {
      list: gql`
        query list {
          list: hersDenmark {
            id
            name: parishCode
            fofmParish
            parishCode
            locality
          }
        }
      `
    };
  }
};
</script>
